import React from "react";
import {
  Edit,
  SimpleForm,
  TextInput,
  ReferenceInput,
  SelectInput,
  FormDataConsumer,
  BooleanInput
} from "react-admin";
import { EditToolbar } from "../../elements/edit-toolbar";

export const EditBatchAttribute = props => (
  <Edit title="Edit Attribute" {...props}>
    <SimpleForm toolbar={<EditToolbar />} redirect={redirect}>
      <ReferenceInput label="Brand" source="brandId" reference="admin/brands">
        <SelectInput optionText="name" />
      </ReferenceInput>
      <TextInput source="name" label="Name of Attribute" />
      <SelectInput
        source="type"
        choices={[
          { id: "text", name: "Text" },
          { id: "date", name: "Date" },
          { id: "number", name: "Number" }
        ]}
      />
      <FormDataConsumer>
        {({ formData, ...props }) => {
          if (formData.type === "number") {
            return <TextInput source="units" {...props} />;
          }
          if (formData.type === "date") {
            return (
              <>
                <BooleanInput
                  label="Is Manufacturing Date?"
                  source="isManufacturingDate"
                  {...props}
                />
                <BooleanInput
                  label="Is Expiration Date?"
                  source="isExpirationDate"
                  {...props}
                />
              </>
            );
          }
        }}
      </FormDataConsumer>
    </SimpleForm>
  </Edit>
);

const redirect = (_basePath, _id, data) =>
  `/admin/brands/${data.brandId}/show/batch-attributes`;
