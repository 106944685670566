import React from "react";
import {
  Show,
  SimpleShowLayout,
  TextField,
  ArrayField,
  Datagrid,
  DateField
} from "react-admin";
import { CardActions, Typography } from "@material-ui/core";

import { AttributesField } from "../../elements/attribute-field";

const Title = ({ record }) => <span>Verification #{record && record.id}</span>;

export const ShowVerification = props => (
  <Show {...props} title={<Title />} actions={<Actions />}>
    <SimpleShowLayout>
      <TextField source="status" />
      <TextField source="code" />
      <TextField source="verifierType" />
      <TextField source="verifierId" label="Verifier ID" />
      <ArrayField source="tag.verifications" label="Previous Verifications">
        <Datagrid rowClick={redirect}>
          <TextField source="status" />
          <TextField source="verifierType" />
          <TextField source="verifierId" />
          <DateField source="createdAt" label="Date" showTime />
        </Datagrid>
      </ArrayField>
    </SimpleShowLayout>
  </Show>
);

const redirect = (_id, basePath, resource) => {
  return `${basePath}/${resource.id}/show`;
};

const Actions = ({ data }) => {
  const { id } = data || {};
  return (
    <CardActions>
      <div style={{ flex: 1 }}>
        <Typography
          variant="display1"
          style={{ display: "inline", marginRight: "10px" }}
        >
          Verification #{id}
        </Typography>
        <AttributesField source="properties" record={data || {}} />
      </div>
    </CardActions>
  );
};
