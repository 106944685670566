import React from "react";
import { List, Datagrid, TextField, DateField } from "react-admin";
import { AttributesField } from "../../elements/attribute-field";

export const DocumentList = props => (
  <List title="Documents" {...props}>
    <Datagrid rowClick="edit">
      <TextField label="ID" source="id" />
      <AttributesField source="properties" />
      <TextField
        label="Tag Serial"
        source="activation.serial"
        sortable={false}
      />
      <DateField label="Created" source="createdAt" showTime />
    </Datagrid>
  </List>
);
