import AccountCircle from "@material-ui/icons/SupervisorAccount";

import { MerchantList } from "./list";
import { UserCreate } from "./create";
import { MerchantEdit } from "./edit";

const userMerchants = {
  name: "merchant/users",
  options: {
    label: "Users"
  },
  icon: AccountCircle,
  list: MerchantList,
  create: UserCreate,
  edit: MerchantEdit
};

export default userMerchants;
