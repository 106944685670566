import React from "react";
import {
  Edit,
  SimpleForm,
  TextInput,
  SelectInput,
  FormDataConsumer,
  ReferenceInput
} from "react-admin";

import { userRoles, merchantRoles } from "../../constants";
import { EditToolbar } from "../../elements/edit-toolbar";

export const UserEdit = props => (
  <Edit title="Edit User" {...props}>
    <SimpleForm toolbar={<EditToolbar />} redirect={redirect}>
      <TextInput source="name" />
      <TextInput source="email" />
      <SelectInput source="role" choices={userRoles} />
      <FormDataConsumer>
        {({ formData }) => {
          if (formData.role === "merchant") {
            return [
              <ReferenceInput
                label="Merchant"
                source="merchantId"
                reference="admin/merchants"
              >
                <SelectInput optionText="company" />
              </ReferenceInput>,
              <SelectInput source="merchantRole" choices={merchantRoles} />
            ];
          }
        }}
      </FormDataConsumer>
    </SimpleForm>
  </Edit>
);

const redirect = (_basePath, _id) => `/admin/users`;
