import React from "react";
import {
  Edit,
  SimpleForm,
  TextInput,
  TextField,
  BooleanInput
} from "react-admin";

import { EditToolbar } from "../../elements/edit-toolbar";

export const Title = ({ record }) => <span>Package #{record.name}</span>;

export const EditPackage = props => (
  <Edit title={<Title />} {...props}>
    <SimpleForm toolbar={<EditToolbar />} redirect={redirect}>
      <TextField source="id" />
      <TextInput source="name" />
      <BooleanInput label="Is Primary?" source="isPrimary" />
      <TextInput source="quantity" />
    </SimpleForm>
  </Edit>
);

const redirect = (_basePath, _id, data) =>
  `/admin/products/${data.productId}/show/packages`;
