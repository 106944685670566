import React from "react";
import { Create, SimpleForm, TextInput, SelectInput } from "react-admin";
import { merchantRoles } from "../../constants";

export const UserCreate = ({ permissions, ...props }) => {
  return (
    <Create title="Register User" {...props}>
      <SimpleForm redirect={redirect}>
        <TextInput source="name" />
        <TextInput source="email" />
        <TextInput source="password" type="password" />
        <SelectInput source="merchantRole" choices={merchantRoles} />
      </SimpleForm>
    </Create>
  );
};

const redirect = (_basePath, _id) => `/merchant/users`;
