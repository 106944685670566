import { Book } from "@material-ui/icons";

const batchAttributes = {
  name: "merchant/batch-attributes",

  options: {
    label: "Batch Attributes"
  },
  icon: Book
};

export default batchAttributes;
