import documents from "./documents";
import orders from "./orders";

import orderItems from "./order-items";

import documentAttributes from "./document-attributes";
import documentTypes from "./document-types";
import rollTypes from "./roll-types";
import users from "./users";
import batches from "./batch";
import brands from "./brands";
import products from "./products";
import batchAttributes from "./batch-attributes";
import activations from "./activations";
import merchantRolls from "./merchant-rolls";
import packages from "./packages";
import payments from "./payments";
import rolls from "./rolls";
import verifications from "./verifications";
import events from "./events";
import printRuns from "./print-runs";

export const documentMerchantAdmin = {
  verifications,
  users,
  documentAttributes,
  documentTypes,
  rollTypes,
  documents,
  merchantRolls,
  printRuns,
  orders,
  orderItems,
  payments
};

export const documentMerchantClerk = {
  documents,
  documentAttributes,
  documentTypes,
  merchantRolls,
  printRuns,
  rollTypes
};

export const brandMerchantAdmin = {
  verifications,
  users,
  products,
  batches,
  brands,
  batchAttributes,
  activations,
  merchantRolls,
  rolls,
  rollTypes,
  packages,
  events,
  printRuns,
  orders,
  orderItems,
  payments
};

export const brandMerchantClerk = {
  batches,
  products,
  brands,
  batchAttributes,
  activations,
  merchantRolls,
  rolls,
  rollTypes,
  packages,
  events,
  printRuns
};
