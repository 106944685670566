import React from "react";
import {
  Create,
  SimpleForm,
  TextInput,
  BooleanInput,
  FormDataConsumer
} from "react-admin";

export const PackageCreate = props => (
  <Create title="Register Package" {...props}>
    <SimpleForm redirect={redirect}>
      <TextInput source="name" />
      <BooleanInput label="Is this a primary package?" source="isPrimary" />

      <FormDataConsumer>
        {({ formData, ...props }) => {
          if (!formData.isPrimary) {
            return <TextInput source="quantity" {...props} />;
          }
        }}
      </FormDataConsumer>
    </SimpleForm>
  </Create>
);

const redirect = (_basePath, _id, data) =>
  `/admin/products/${data.productId}/show/packages`;
