import { Book } from "@material-ui/icons";
import { CreateDocument } from "./create";
import { EditDocument } from "./edit";
import { DocumentList } from "./list";

const documents = {
  name: "merchant/documents",
  options: {
    label: "Documents"
  },
  icon: Book,
  create: CreateDocument,
  edit: EditDocument,
  list: DocumentList
};

export default documents;
