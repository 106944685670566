import React from "react";
import {
  Show,
  SimpleShowLayout,
  ReferenceManyField,
  ReferenceField,
  TextField,
  Pagination
} from "react-admin";
import {
  CardActions,
  Typography,
  LinearProgress,
  Chip
} from "@material-ui/core";

const Title = ({ record }) => <span>Print Job #{record.id}</span>;

export const ShowPrintRun = props => (
  <Show title={<Title />} actions={<Actions />} {...props}>
    <SimpleShowLayout>
      <ProgressField />

      <ReferenceField
        label="Roll Type"
        source="rollTypeId"
        reference="admin/roll-types"
      >
        <TextField source="name" />
      </ReferenceField>

      <TextField source="status" />

      <TextField label="Number Of Rolls" source="volume" />

      <ReferenceManyField
        reference="admin/rolls"
        target="printRunId"
        label="Roll Serial Numbers"
        pagination={<Pagination />}
      >
        <RollGrid />
      </ReferenceManyField>
    </SimpleShowLayout>
  </Show>
);

export const RollGrid = ({ ids, data }) => {
  return (
    <>
      {(ids || []).map(id => {
        const { serial } = data[id] || {};
        return (
          <Chip
            key={id}
            style={{
              fontFamily: "Monospace",
              margin: "0 1em 1em 0"
            }}
            label={serial}
          />
        );
      })}
    </>
  );
};

export const ProgressField = ({ record }) => {
  if (record.status === "generated") {
    return <div />;
  }

  if (record.progress === 0) {
    return <LinearProgress variant="indeterminate" />;
  }

  return <LinearProgress variant="determinate" value={record.progress * 100} />;
};

const Actions = ({ data }) => {
  const { id } = data || {};
  return (
    <CardActions>
      <Typography style={{ flex: 1 }} variant="display1">
        Print Job #{id}
      </Typography>
    </CardActions>
  );
};
