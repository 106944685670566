import React from "react";
import {
  List,
  Datagrid,
  TextField,
  DateField,
  ExportButton,
  RefreshButton,
  CREATE
} from "react-admin";
import { Button, CardActions, Chip } from "@material-ui/core";
import { connect } from "react-redux";
import {
  showNotification,
  ReferenceManyField,
  SingleFieldList,
  FunctionField
} from "react-admin";
import { push } from "react-router-redux";

import { dataProvider } from "../../providers";

export const OrderList = props => (
  <List title="Orders" actions={<Actions />} {...props}>
    <Datagrid rowClick="show">
      <TextField source="id" />
      <ReferenceManyField
        reference="merchant/order-items"
        target="orderId"
        label={"Order Items"}
      >
        <SingleFieldList>
          <FunctionField
            render={record =>
              record && (
                <Chip
                  key={record.id}
                  color="primary"
                  style={{
                    margin: "1em 1em 1em 0"
                  }}
                  label={record.quantity + " " + record.rollType.name}
                />
              )
            }
          />
        </SingleFieldList>
      </ReferenceManyField>
      <TextField source="status" />
      <DateField label="Date" source="createdAt" />
    </Datagrid>
  </List>
);

const OrderButton = props => {
  function handleClick() {
    const { push, showNotification } = props;
    dataProvider(CREATE, "merchant/orders", { data: {} }).then(({ data }) => {
      showNotification("Order Created");
      push(`/merchant/orders/${data.id}/show`);
    });
  }

  return (
    <Button variant="raised" color="secondary" onClick={handleClick}>
      Create Order
    </Button>
  );
};

const CreateOrderButton = connect(
  null,
  { showNotification, push }
)(OrderButton);

const Actions = ({
  bulkActions,
  basePath,
  currentSort,
  displayedFilters,
  exporter,
  filters,
  filterValues,
  onUnselectItems,
  resource,
  selectedIds,
  showFilter,
  total
}) => {
  return (
    <CardActions>
      {bulkActions &&
        React.cloneElement(bulkActions, {
          basePath,
          filterValues,
          resource,
          selectedIds,
          onUnselectItems
        })}
      {filters &&
        React.cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: "button"
        })}
      <ExportButton
        disabled={total === 0}
        resource={resource}
        sort={currentSort}
        filter={filterValues}
        exporter={exporter}
      />
      <RefreshButton />
      <CreateOrderButton />
    </CardActions>
  );
};
