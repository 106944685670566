import React from "react";
import { LinearProgress } from "@material-ui/core";

export function ProgressField({ record }) {
  if (record.status !== "pending") {
    return <span>{record.status}</span>;
  }

  if (record.progress === undefined || record.progress === 0) {
    return <LinearProgress color="secondary" variant="indeterminate" />;
  }

  return <LinearProgress variant="determinate" value={record.progress * 100} />;
}
