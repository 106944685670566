import React from "react";
import {
  Edit,
  SimpleForm,
  SelectInput,
  ReferenceInput,
  FormDataConsumer,
  NumberInput
} from "react-admin";

export const EditOrderDetails = ({ permissions, ...props }) => {
  return (
    <Edit title="Create Order Items" {...props}>
      <SimpleForm redirect={redirect}>
        <ReferenceInput
          label="Roll Type"
          source="rollTypeId"
          reference="merchant/roll-types"
          addLabel={false}
        >
          <SelectInput optionText="name" />
        </ReferenceInput>

        <FormDataConsumer>
          {({ formData }) => {
            if (formData.rollTypeId) {
              return (
                <ReferenceInput
                  label="Unit Price"
                  source="rollTypeId"
                  reference="merchant/roll-types"
                  addLabel={false}
                >
                  <SelectInput optionText="price" />
                </ReferenceInput>
              );
            }
          }}
        </FormDataConsumer>
        <NumberInput source="quantity" />
      </SimpleForm>
    </Edit>
  );
};

const redirect = (_basePath, _id, data) =>
  `/merchant/orders/${data.orderId}/show`;
