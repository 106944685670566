import React from "react";
import {
  Create,
  SimpleForm,
  TextInput,
  ReferenceInput,
  SelectInput
} from "react-admin";

export const CreateBrand = props => (
  <Create title="Create Brand" {...props}>
    <SimpleForm redirect={redirect}>
      <ReferenceInput
        label="Merchant"
        source="merchantId"
        reference="admin/merchants"
      >
        <SelectInput optionText="company" />
      </ReferenceInput>
      <TextInput source="name" />
    </SimpleForm>
  </Create>
);

const redirect = (_basePath, _id, data) =>
  `/admin/merchants/${data.merchantId}/show`;
