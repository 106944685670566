import Folder from "@material-ui/icons/Folder";

import { PrintRunList } from "./list";
import { PrintRunCreate } from "./create";
import { ShowPrintRun } from "./show";

const printRuns = {
  name: "admin/print-runs",
  icon: Folder,
  options: { label: "Print Jobs" },
  list: PrintRunList,
  create: PrintRunCreate,
  show: ShowPrintRun
};

export default printRuns;
