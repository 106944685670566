import { Book } from "@material-ui/icons";
import { CreateActivations } from "./create";
import { EditActivations } from "./edit";

const activations = {
  name: "merchant/activations",
  options: {
    label: "Activations"
  },
  icon: Book,
  create: CreateActivations,
  edit: EditActivations
};

export default activations;
