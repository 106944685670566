import React from "react";
import { List, Datagrid, TextField, DateField } from "react-admin";

export const ListVerifications = props => (
  <List title="Verifications" {...props}>
    <Datagrid rowClick="show">
      <TextField source="status" />
      <TextField source="code" />
      <TextField source="verifierId" />
      <TextField source="entity" label="Entity" sortable={false} />
      <DateField label="Created" source="createdAt" showTime/>
    </Datagrid>
  </List>
);
 