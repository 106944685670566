import React from "react";
import { List, Datagrid, TextField, DateField } from "react-admin";

const linkClick = (id, basePath, record) =>
  record.merchantType === "brand-creator"
    ? `/admin/merchants/${record.id}/show`
    : `/admin/merchants/${record.id}/show/document-types`;

export const MerchantList = props => (
  <List title="Merchants" {...props}>
    <Datagrid rowClick={linkClick}>
      <TextField source="id" />
      <TextField source="company" />
      <TextField source="merchantType" />
      <DateField label="Created" source="createdAt" />
    </Datagrid>
  </List>
);
