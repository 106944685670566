import React from "react";
import {
  Create,
  SimpleForm,
  SelectInput,
  ReferenceInput,
  ReferenceField,
  FormDataConsumer,
  ReferenceManyField
} from "react-admin";
import { AttributeGrid } from "../../elements/attribute-grid";

export const CreateBatch = props => {
  return (
    <Create {...props} title={"Create Batch"}>
      <SimpleForm redirect={redirect} defaultValue={{ attributes: {} }}>
        <ReferenceInput
          label="Product"
          source="productId"
          reference="merchant/products"
        >
          <SelectInput optionText="name" />
        </ReferenceInput>
        <FormDataConsumer>
          {({ formData, ...props }) => {
            if (formData.productId) {
              return (
                <ReferenceField
                  {...props}
                  source="productId"
                  reference="merchant/products"
                  record={{ productId: formData.productId }}
                  linkType={false}
                >
                  <ReferenceManyField
                    label="Batch Attributes"
                    reference="merchant/batch-attributes"
                    target="brandId"
                    source="brandId"
                  >
                    <AttributeGrid />
                  </ReferenceManyField>
                </ReferenceField>
              );
            }
          }}
        </FormDataConsumer>
      </SimpleForm>
    </Create>
  );
};

const redirect = _basePath => `/merchant/batches`;
