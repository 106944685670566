import { ShopTwo } from "@material-ui/icons";

const packages = {
  name: "merchant/packages",
  options: {
    label: "Packages"
  },
  icon: ShopTwo
};

export default packages;
