import { Book } from "@material-ui/icons";
import { CreateBatchAttribute } from "./create";
import { EditBatchAttribute } from "./edit";

const batchAttributes = {
  name: "admin/batch-attributes",

  options: {
    label: "Batch Attributes"
  },
  icon: Book,
  create: CreateBatchAttribute,
  edit: EditBatchAttribute
};

export default batchAttributes;
