import Check from "@material-ui/icons/Check";

import { ListVerifications } from "./list";
import { ShowVerification } from "./show";

const verifications = {
  name: "merchant/verifications",
  icon: Check,
  options: { label: "Verifications" },
  list: ListVerifications,
  show: ShowVerification
};

export default verifications;
