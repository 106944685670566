import React from "react";
import {
  Edit,
  SimpleForm,
  SelectInput,
  TextInput,
  NumberInput
} from "react-admin";

export const EditPayments = ({ permissions, ...props }) => {
  return (
    <Edit title="Edit Payment" {...props}>
      <SimpleForm redirect={redirect}>
      <SelectInput
          source="method"
          choices={[
            { id: "bank", name: "Bank" },
          ]}
        />
        <SelectInput
          source="bank"
          choices={[
            { id: "equity", name: "Equity" },
            { id: "stanbic", name: "Stanbic" },
          ]}
        />
         <NumberInput source="amount" />
        <TextInput source="reference" />
      </SimpleForm>
    </Edit>
  );
};

const redirect = (_basePath, _id, data) =>
  `/merchant/orders/${data.orderId}/show`;
