import React from "react";
import {
  Edit,
  SimpleForm,
  TextInput,
  TextField,
  SelectInput
} from "react-admin";
import { merchantType } from "../../constants";

export const Title = ({ record }) => <span>Merchant #{record.id}</span>;

export const MerchantEdit = props => (
  <Edit title={<Title />} {...props}>
    <SimpleForm redirect={redirect}>
      <TextField source="id" />
      <TextInput source="company" />
      <SelectInput source="merchantType" choices={merchantType} />
      <TextInput source="phoneNumber" />
      <TextInput source="email" />
      <TextInput source="address" />
    </SimpleForm>
  </Edit>
);

const redirect = (_basePath, id) => `/admin/merchants/${id}/show`;
