import React from "react";
import {
  Create,
  SimpleForm,
  TextInput,
  SelectInput,
  ReferenceInput,
  FormDataConsumer
} from "react-admin";
import { userRoles, merchantRoles } from "../../constants";

export const UserCreate = props => (
  <Create title="Register User" {...props}>
    <SimpleForm redirect={redirect}>
      <TextInput source="name" />
      <TextInput source="email" />
      <TextInput source="password" type="password" />
      <SelectInput source="role" choices={userRoles} />
      <FormDataConsumer>
        {({ formData }) => {
          if (formData.role === "merchant") {
            return [
              <ReferenceInput
                label="Merchant"
                source="merchantId"
                reference="admin/merchants"
              >
                <SelectInput optionText="company" />
              </ReferenceInput>,
              <SelectInput source="merchantRole" choices={merchantRoles} />
            ];
          }
        }}
      </FormDataConsumer>
    </SimpleForm>
  </Create>
);

const redirect = (_basePath, _id) => `/admin/users`;
