import React from "react";
import {
  Edit,
  SimpleForm,
  TextInput,
  ReferenceInput,
  SelectInput
} from "react-admin";
import { EditToolbar } from "../../elements/edit-toolbar";

export const EditBrand = props => (
  <Edit title={<Title />} {...props}>
    <SimpleForm toolbar={<EditToolbar />} redirect={redirect}>
      <ReferenceInput
        label="Merchant"
        source="merchantId"
        reference="admin/merchants"
      >
        <SelectInput optionText="company" />
      </ReferenceInput>
      <TextInput source="name" />
    </SimpleForm>
  </Edit>
);

const Title = ({ record }) => <span>{record.name}</span>;

const redirect = (_basePath, id) => `/admin/brands/${id}/show`;
