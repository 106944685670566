import users from "./users";
import brands from "./brands";
import documents from "./documents";
import batch from "./batch";
import merchants from "./merchants";
import rollTypes from "./roll-types";
import merchantRolls from "./merchant-rolls";
import printRuns from "./print-runs";
import products from "./products";
import productAttributes from "./product-attributes";
import batchAttributes from "./batch-attributes";
import packages from "./packages";
import activations from "./activations";
import documentAttributes from "./document-attribute";
import documentType from "./document-types";
import rolls from "./rolls";
import verifications from "./verifications";
import events from "./events";
import orders from "./orders";

import orderItems from "./order-items";

import payments from "./payments";

export default {
  verifications,
  brands,
  batch,
  orders,
  orderItems,
  payments,
  merchants,
  products,
  merchantRolls,
  printRuns,
  productAttributes,
  batchAttributes,
  packages,
  activations,
  documentAttributes,
  documentType,
  documents,
  rolls,
  rollTypes,
  users,
  events
};
