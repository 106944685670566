import { Book } from "@material-ui/icons";
import { CreateBrand } from "./create";
import { ShowBrand } from "./show";
import { EditBrand } from "./edit";

const brands = {
  name: "admin/brands",
  options: {
    label: "Brands"
  },
  icon: Book,
  create: CreateBrand,
  edit: EditBrand,
  show: ShowBrand
};

export default brands;
