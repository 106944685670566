import React from "react";
import {
  Show,
  SimpleShowLayout,
  TextField,
  UPDATE,
  NumberField,
  Datagrid,
  ReferenceManyField,
  FunctionField,
  showNotification,
  FormDataConsumer,
  ReferenceField,
  refreshView
} from "react-admin";
import { Typography, Button, Grid } from "@material-ui/core";
import { ListButton } from "../../elements/list-button";
import { connect } from "react-redux";
import { dataProvider } from "../../providers";
import { push } from "react-router-redux";

export const OrderDetails = props => (
  <Show title={<Title />} actions={<Actions />} {...props}>
    <SimpleShowLayout>
      <ReferenceField
        label="Merchant"
        source="merchantId"
        reference="admin/merchants"
        linkType={null}
      >
        <TextField source="company" />
      </ReferenceField>
      <FunctionField
        label="Total Cost"
        render={record => `UGX ${record.cost}`}
      />
      <FunctionField
        label="Status"
        render={record => record.status.toUpperCase()}
      />
      <ReferenceManyField
        reference="admin/order-items"
        target="orderId"
        label={"Order Items"}
      >
        <Datagrid>
          <TextField source="id" />
          <TextField label="Stickers" source="rollType.name" />
          <NumberField source="quantity" />
          <NumberField label="Unit Price" source="unitPrice" />
          <NumberField label="Total" source="totalAmount" />
        </Datagrid>
      </ReferenceManyField>

      <FormDataConsumer>
        {({ formData }) => {
          if (formData.status === "payment-pending") {
            return <SubmitButton order={formData} />;
          }
        }}
      </FormDataConsumer>

      <ReferenceManyField
        reference="admin/payments"
        target="orderId"
        label={"Payment"}
      >
        <Datagrid>
          <TextField source="id" />
          <TextField label="Payment Method" source="method" />
          <NumberField source="amount" />
          <TextField label="Bank" source="bank" />
          <TextField label="Reference" source="reference" />
        </Datagrid>
      </ReferenceManyField>
    </SimpleShowLayout>
  </Show>
);

const Title = ({ record }) => <span>Order #{record && record.id}</span>;

const Actions = ({ data = {}, resource }) => {
  return (
    <Grid
      spacing={16}
      alignItems="center"
      container
      style={{ padding: "0 0 32px" }}
    >
      <Grid item>
        <ListButton resource={resource} />
      </Grid>
      <Grid item style={{ flex: 1 }}>
        <Typography variant="display1">Order #{data.id}</Typography>
      </Grid>
    </Grid>
  );
};

const Submit = ({ order, refreshView, showNotification }) => {
  if (!order.cost || order.status !== "payment-pending") {
    return <div />;
  }

  function handleClick() {
    dataProvider(UPDATE, "admin/orders", {
      id: order.id,
      data: { status: "paid" }
    }).then(() => {
      showNotification("Order Payment Confirmed");
      refreshView();
    });
  }

  return (
    <Button variant="raised" color="secondary" onClick={handleClick}>
      Confirm Payment
    </Button>
  );
};

const SubmitButton = connect(
  null,
  { showNotification, refreshView, push }
)(Submit);
