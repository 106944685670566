import React from "react";
import {
  Edit,
  ArrayInput,
  SimpleFormIterator,
  SimpleForm,
  TextInput,
  SelectInput,
  FormDataConsumer,
  BooleanInput
} from "react-admin";
import { EditToolbar } from "../../elements/edit-toolbar";

export const EditDocumentAttribute = props => (
  <Edit title="Edit Attribute" {...props}>
    <SimpleForm toolbar={<EditToolbar />} redirect={redirect}>
      <TextInput source="name" label="Name Of Attribute" />
      <SelectInput
        source="type"
        choices={[
          { id: "text", name: "Text" },
          { id: "date", name: "Date" },
          { id: "number", name: "Number" },
          { id: "drop-down", name: "Drop Down" }
        ]}
      />
      <BooleanInput
        label="Enable editing when updating a document?"
        source="isEditable"
        defaultValue={true}
      />
      <FormDataConsumer>
        {({ formData, ...props }) => {
          if (formData.type === "number") {
            return <TextInput source="units" {...props} />;
          }

          if (formData.type === "drop-down") {
            return (
              <ArrayInput source="options" {...props}>
                <SimpleFormIterator>
                <TextInput label="Option" source="value" type="text" />
                </SimpleFormIterator>
              </ArrayInput>
            );
          }

          if (formData.type === "date") {
            return (
              <>
                <BooleanInput
                  label="Is Issuing Date?"
                  source="isIssuingDate"
                  {...props}
                />
                <BooleanInput
                  label="Is Expiration Date?"
                  source="isExpirationDate"
                  {...props}
                />
              </>
            );
          }
        }}
      </FormDataConsumer>
    </SimpleForm>
  </Edit>
);

const redirect = (_basePath, _id, data) =>
  `/admin/document-types/${data.documentTypeId}/show/document-attributes`;
