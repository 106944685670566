import React from "react";
import { List, Datagrid, TextField } from "react-admin";

export const RollTypeList = props => (
  <List title="Roll Types" {...props}>
    <Datagrid rowClick="edit">
      <TextField source="id" />
      <TextField source="name" />
      <TextField source="description" />
      <TextField label="Number of Tags" source="size" />
      <TextField label="Price" source="price" />
    </Datagrid>
  </List>
);
