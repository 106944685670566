import React from "react";
import {
  TabbedShowLayout,
  Tab,
  Show,
  ReferenceManyField,
  Datagrid,
  DateField,
  TextField
} from "react-admin";
import { CardActions, Typography } from "@material-ui/core";
import { SimpleMenu } from "../../elements/menu";
import { AttributesField } from "../../elements/attribute-field";

export const ShowDocumentType = props => (
  <Show title={<Title />} actions={<Actions />} {...props}>
    <TabbedShowLayout>
      <Tab label="Document" path="documents">
        <ReferenceManyField
          reference="admin/documents"
          target="documentTypeId"
          addLabel={false}
        >
          <Datagrid>
            <TextField source="id" />
            <AttributesField label="Attributes" source="properties" />
            <DateField source="createdAt" />
            <DateField label="ActivatedAt" source="activation.createdAt" showTime />
          </Datagrid>
        </ReferenceManyField>
      </Tab>
      <Tab label="Document Attributes" path="document-attributes">
        <ReferenceManyField
          reference="admin/document-attributes"
          target="documentTypeId"
          addLabel={false}
        >
          <Datagrid rowClick="edit">
            <TextField source="id" />
            <TextField source="name" />
            <TextField source="type" />
          </Datagrid>
        </ReferenceManyField>
      </Tab>
    </TabbedShowLayout>
  </Show>
);

const Title = ({ record }) => <span>{record && record.name}</span>;

const Actions = ({ data }) => {
  const { id: documentTypeId, merchantId, name } = data || {};
  return (
    <CardActions>
      <Typography style={{ flex: 1 }} variant="display1">
        {name}
      </Typography>
      <SimpleMenu
        items={[
          {
            label: "Create Document Attributes",
            pathname: "/admin/document-attributes/create",
            state: { record: { merchantId, documentTypeId } }
          },
          {
            label: "Edit Document Type",
            pathname: `/admin/document-types/${documentTypeId}`
          }
        ]}
      />
    </CardActions>
  );
};
