import React from "react";
import {
  TabbedShowLayout,
  Tab,
  Show,
  TextField,
  ReferenceManyField,
  DateField,
  SimpleList,
  ReferenceField,
  Datagrid,
  Pagination
} from "react-admin";
import { CardActions, Typography } from "@material-ui/core";
import { SimpleMenu } from "../../elements/menu";

export const MerchantShow = props => (
  <Show title={<Title />} actions={<Actions />} {...props}>
    <CustomTabLayout />
  </Show>
);
const linkClick = (id, basePath, record) =>
  `/admin/document-types/${record.id}/show/documents`;

const CustomTabLayout = props => {
  return (
    <TabbedShowLayout {...props}>
      {props.record.merchantType === "brand-creator"
        ? [
            <Tab label="brands">
              <ReferenceManyField
                reference="admin/brands"
                target="merchantId"
                addLabel={false}
              >
                <SimpleList
                  linkType="show"
                  primaryText={record => record && record.name}
                />
              </ReferenceManyField>
            </Tab>
          ]
        : []}
      {props.record.merchantType === "document-issuer"
        ? [
            <Tab label="Document Types" path="document-types">
              <ReferenceManyField
                reference="admin/document-types"
                target="merchantId"
                addLabel={false}
              >
                <Datagrid rowClick={linkClick}>
                  <TextField source="id" />
                  <TextField source="name" />
                  <DateField source="createdAt" />
                </Datagrid>
              </ReferenceManyField>
            </Tab>
          ]
        : []}
      <Tab label="Rolls" path="rolls">
        <ReferenceManyField
          pagination={<Pagination rowsPerPageOptions={[10, 25, 50, 100]} />}
          reference="admin/merchant-rolls"
          target="merchantId"
          addLabel={false}
        >
          <Datagrid rowClick="edit">
            <ReferenceField
              label="Roll Serial"
              reference="admin/rolls"
              source="rollId"
              linkType={false}
            >
              <TextField source="serial" />
            </ReferenceField>
            <ReferenceField
              label="Roll Type"
              reference="admin/rolls"
              source="rollId"
              linkType={false}
            >
              <ReferenceField
                reference="admin/print-runs"
                source="printRunId"
                linkType={false}
              >
                <ReferenceField
                  reference="admin/roll-types"
                  source="rollTypeId"
                  linkType={false}
                >
                  <TextField source="name" />
                </ReferenceField>
              </ReferenceField>
            </ReferenceField>
            <DateField source="createdAt" label="Assigned At" showTime />
          </Datagrid>
        </ReferenceManyField>
      </Tab>
      ,
      <Tab label="details" path="details">
        <TextField source="company" />
        <TextField source="phoneNumber" />
        <TextField source="email" />
        <TextField source="address" />
        <DateField source="createdAt" label="Registered On" />
      </Tab>
    </TabbedShowLayout>
  );
};

const Title = ({ record }) => <span>{record && record.company}</span>;

const Actions = ({ data }) => {
  const { id: merchantId, company, merchantType } = data || {};
  return (
    <CardActions>
      <Typography style={{ flex: 1 }} variant="display1">
        {company}
      </Typography>

      <SimpleMenu
        items={[
          ...(merchantType === "document-issuer"
            ? [
                {
                  label: "Create Document",
                  pathname: "/admin/document-types/create",
                  state: { record: { merchantId } }
                }
              ]
            : [
                {
                  label: "Create Brand",
                  pathname: "/admin/brands/create",
                  state: { record: { merchantId } }
                }
              ]),
          {
            label: "Assign Rolls  ",
            pathname: "/admin/merchant-rolls/create",
            state: { record: { merchantId } }
          },
          {
            label: "Edit",
            pathname: `/admin/merchants/${merchantId}`
          }
        ]}
      />
    </CardActions>
  );
};
