import { Book } from "@material-ui/icons";
import { CreateBatch } from "./create";
import { EditBatch } from "./edit";
import { ShowBatch } from "./show";

const batch = {
  name: "admin/batches",
  options: {
    label: "Batches"
  },
  icon: Book,
  create: CreateBatch,
  edit: EditBatch,
  show: ShowBatch
};

export default batch;
