import React from "react";
import {
  TabbedShowLayout,
  Tab,
  Show,
  ReferenceField,
  ReferenceManyField,
  TextField,
  Datagrid,
  DateField,
  SingleFieldList
} from "react-admin";
import { CardActions, Typography } from "@material-ui/core";
import { SimpleMenu } from "../../elements/menu";
import { TagField } from "../../elements/tag-field";
import { AttributesField } from "../../elements/attribute-field";

const Title = ({ record }) => <span>{record.name}</span>;

export const ShowBatch = props => (
  <Show title={<Title />} actions={<Actions />} {...props}>
    <TabbedShowLayout>
      <Tab label="Activations">
        <ReferenceManyField
          reference="admin/activations"
          target="batchId"
          addLabel={false}
        >
          <Datagrid rowClick="edit">
            <ReferenceField
              reference="admin/packages"
              source="packageId"
              linkType={false}
            >
              <TextField source="name" />
            </ReferenceField>
            <TagField source="start" />
            <TagField source="end" />
            <TextField source="count" />
            <DateField label="Created" source="createdAt" showTime />
            <ReferenceManyField
              label="Created By"
              reference="admin/events"
              source="id"
              target="activationId"
              linkType={false}
              filter={{ eventAction: "create" }}
            >
              <SingleFieldList>
                <TextField source="user.name" />
              </SingleFieldList>
            </ReferenceManyField>
          </Datagrid>
        </ReferenceManyField>
      </Tab>
    </TabbedShowLayout>
  </Show>
);

const Actions = ({ data }) => {
  const record = data || {};
  const { id: batchId, productId, product = { brand: {} } } = record;
  return (
    <CardActions>
      <div style={{ flex: 1 }}>
        <Typography
          variant="display1"
          style={{
            display: "inline",
            marginRight: "10px"
          }}
        >
          {product.brand.name}
        </Typography>
        <AttributesField source="properties" record={product} />
      </div>
      <AttributesField source="properties" record={record} />
      <SimpleMenu
        items={[
          {
            label: "Create Activation",
            pathname: "/admin/activations/create",
            state: { record: { batchId, productId } }
          },
          {
            label: "Edit",
            pathname: `/admin/batches/${batchId}`
          }
        ]}
      />
    </CardActions>
  );
};
