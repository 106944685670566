import React from "react";
import {
  Edit,
  SimpleForm,
  ReferenceInput,
  SelectInput,
  ReferenceManyField,
  FormDataConsumer
} from "react-admin";
import { AttributeGrid } from "../../elements/attribute-grid";

export const EditProduct = props => {
  return (
    <Edit title="Edit Product" {...props}>
      <SimpleForm redirect={redirect}>
        <ReferenceInput
          label="Brand"
          source="brandId"
          reference="admin/brands"
          disabled
        >
          <SelectInput optionText="name" />
        </ReferenceInput>

        <FormDataConsumer>
          {({ formData, ...props }) => {
            if (formData.brandId) {
              return (
                <ReferenceManyField
                  {...props}
                  label="Product Attributes"
                  reference="admin/product-attributes"
                  target="brandId"
                  record={{ id: formData.brandId }}
                >
                  <AttributeGrid />
                </ReferenceManyField>
              );
            }
          }}
        </FormDataConsumer>
      </SimpleForm>
    </Edit>
  );
};

const redirect = (_basePath, id) => `/admin/products/${id}/show`;
