import React from "react";
import {
  TabbedShowLayout,
  Tab,
  Show,
  ReferenceManyField,
  SimpleList,
  Datagrid,
  TextField,
  DateField
} from "react-admin";
import { CardActions, Typography } from "@material-ui/core";
import { SimpleMenu } from "../../elements/menu";
import { AttributesField } from "../../elements/attribute-field";

const Title = ({ record }) => <span>{record && record.name}</span>;

export const ShowProduct = props => (
  <Show title={<Title />} actions={<Actions />} {...props}>
    <TabbedShowLayout>
      <Tab label="Batches">
        <ReferenceManyField
          reference="admin/batches"
          target="productId"
          addLabel={false}
        >
          <Datagrid rowClick="show">
            <TextField source="id" />
            <AttributesField label="Attributes" source="properties" />
            <DateField source="createdAt" showTime />
          </Datagrid>
        </ReferenceManyField>
      </Tab>
      <Tab label="Packages" path="packages">
        <ReferenceManyField
          reference="admin/packages"
          target="productId"
          addLabel={false}
        >
          <SimpleList
            linkType="edit"
            primaryText={record =>
              `${record.name} ${
                record.isPrimary ? "" : " of " + record.quantity
              }`
            }
          />
        </ReferenceManyField>
      </Tab>
    </TabbedShowLayout>
  </Show>
);

const Actions = ({ data }) => {
  const { id: productId, brand, brandId } = data || {};
  return (
    <CardActions>
      <div style={{ flex: 1 }}>
        <Typography
          variant="display1"
          style={{ display: "inline", marginRight: "10px" }}
        >
          {brand && brand.name}
        </Typography>
        <AttributesField source="properties" record={data || {}} />
      </div>

      <SimpleMenu
        items={[
          {
            label: "Create Batch",
            pathname: "/admin/batches/create",
            state: { record: { productId, brandId } }
          },
          {
            label: "Create Package",
            pathname: "/admin/packages/create",
            state: { record: { productId, brandId } }
          },
          {
            label: "Edit",
            pathname: `/admin/products/${productId}`
          }
        ]}
      />
    </CardActions>
  );
};
