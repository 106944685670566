import React from "react";
import {
  Edit,
  SimpleForm,
  ReferenceInput,
  SelectInput,
  TextInput
} from "react-admin";

export const EditActivations = props => {
  return (
    <Edit title="Edit Activation" {...props}>
      <SimpleForm redirect={redirect}>
        <ReferenceInput
          label="Batch"
          source="batchId"
          reference="merchant/batches"
          disabled
        >
          <SelectInput optionText="name" />
        </ReferenceInput>
        <TextInput label="Start Serial" source="start" />
        <TextInput label="End Serial" source="end" />
      </SimpleForm>
    </Edit>
  );
};

const redirect = (_basePath, _id, data) =>
  `/merchant/batches/${data.batchId}/show`;
