import { Book } from "@material-ui/icons";

const brands = {
  name: "merchant/brands",
  options: {
    label: "Brands"
  },
  icon: Book
};

export default brands;
