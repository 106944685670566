import React, { useState } from "react";
import {
  Create,
  SimpleForm,
  SelectInput,
  TextInput,
  NumberInput
} from "react-admin";

export const CreatePayments = ({ permissions, ...props }) => {

  const [bank, setBank] = useState({visible: false});

  return (
    <Create title="Make Payment" {...props}>
      <SimpleForm redirect={redirect}>
        <SelectInput
          source="method" choices={[{ id: "bank", name: "Bank" }]} 
          onChange={(event, key, payload) =>
            setBank({ visible: key === "bank" })
          }
        />
        {bank.visible && (
          <React.Fragment>
          <SelectInput
          source="bank"
          choices={[
            { id: "equity", name: "Equity" },
            { id: "stanbic", name: "Stanbic" }
          ]}
        />
        <TextInput source="reference" />
        </React.Fragment>
        )}

        <NumberInput source="amount" />
      
      </SimpleForm>
    </Create>
  );
};

const redirect = (_basePath, _id, data) =>
  `/merchant/orders/${data.orderId}/show`;
