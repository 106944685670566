import { CardTravel } from "@material-ui/icons";
import { CreateOrder } from "./create";
import { OrderList } from "./list";
import { OrderDetails } from "./show";

const orders = {
  name: "merchant/orders",
  options: {
    label: "Orders"
  },
  icon: CardTravel,
  create: CreateOrder,
  list: OrderList,
  show: OrderDetails
};

export default orders;
