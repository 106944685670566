import React from "react";
import {
  Create,
  SimpleForm,
  ReferenceInput,
  SelectInput,
  LongTextInput
} from "react-admin";

export const CreateMerchantRolls = props => (
  <Create title="Assign Roll" {...props}>
    <SimpleForm redirect={redirect}>
      <ReferenceInput
        label="Merchant"
        source="merchantId"
        reference="admin/merchants"
      >
        <SelectInput optionText="company" />
      </ReferenceInput>
      <LongTextInput label="Roll Serial Number" source="rollSerials" />
    </SimpleForm>
  </Create>
);

const redirect = (_basePath, _id, data) =>
  `/admin/merchants/${data.merchantId}/show/rolls`;
