import React from "react";
import { Tooltip, Chip } from "@material-ui/core";

const chipStyle = {
  marginRight: "5px"
};

export const AttributesField = ({ record, source }) => {
  const values = (record[source] || []).sort((a, b) => {
    return a.attribute.type < b.attribute.type ? 1 : -1;
  });
  return (
    <>
      {values.map(item => {
        if (item.attribute.type === "number") {
          return (
            <Tooltip key={item.id} title={item.attribute.name}>
              <Chip
                color="secondary"
                label={item.value + item.attribute.units}
                style={chipStyle}
              />
            </Tooltip>
          );
        }
        if (item.attribute.type === "date") {
          return (
            <Tooltip key={item.id} title={item.attribute.name}>
              <Chip color="secondary" label={item.value} style={chipStyle} />
            </Tooltip>
          );
        }

        return (
          <Tooltip key={item.id} title={item.attribute.name}>
            <Chip color="primary" label={item.value} style={chipStyle} />
          </Tooltip>
        );
      })}
    </>
  );
};

AttributesField.defaultProps = {
  sortable: false
};
