import { Assignment } from "@material-ui/icons";
import { CreateMerchantRolls } from "./create";
import { EditMerchantRoll } from "./edit";

const merchantRolls = {
  name: "admin/merchant-rolls",
  options: {
    label: "Merchant Roll"
  },
  icon: Assignment,
  create: CreateMerchantRolls,
  edit: EditMerchantRoll
};

export default merchantRolls;
