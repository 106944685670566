import React from "react";
import {
  Edit,
  SimpleForm,
  TextInput,
  ReferenceInput,
  SelectInput
} from "react-admin";
import { EditToolbar } from "../../elements/edit-toolbar";

export const EditDocumentType = props => (
  <Edit title={<Title />} {...props}>
    <SimpleForm toolbar={<EditToolbar />} redirect={redirect}>
      <ReferenceInput
        label="Merchant"
        source="merchantId"
        reference="admin/merchants"
      >
        <SelectInput optionText="company" />
      </ReferenceInput>
      <TextInput source="name" />
    </SimpleForm>
  </Edit>
);

const Title = ({ record }) => <span>{record.ccmpany}</span>;

const redirect = (_basePath, _id, data) =>
  `/admin/merchants/${data.merchantId}/show/document-types`;
