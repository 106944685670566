import React from "react";
import {
  Create,
  SimpleForm,
  ReferenceInput,
  SelectInput,
  ReferenceManyField,
  FormDataConsumer
} from "react-admin";
import { AttributeGrid } from "../../elements/attribute-grid";

export const CreateBatch = props => {
  return (
    <Create {...props} title={"Batch"}>
      <SimpleForm redirect={redirect} defaultValue={{ attributes: {} }}>
        <ReferenceInput
          label="Product"
          source="productId"
          reference="admin/products"
        >
          <SelectInput optionText="name" />
        </ReferenceInput>

        <FormDataConsumer>
          {({ formData, ...props }) => {
            if (formData.brandId) {
              return (
                <ReferenceManyField
                  {...props}
                  label="Batch Attributes"
                  reference="admin/batch-attributes"
                  target="brandId"
                  record={{ id: formData.brandId }}
                >
                  <AttributeGrid />
                </ReferenceManyField>
              );
            }
          }}
        </FormDataConsumer>
      </SimpleForm>
    </Create>
  );
};

const redirect = (_basePath, _id, data) =>
  `/admin/products/${data.productId}/show`;
