import AccountCircle from "@material-ui/icons/Label";

import { RollTypeList } from "./list";
import { RollTypeCreate } from "./create";
import { RollTypeEdit } from "./edit";

const rollTypes = {
  name: "admin/roll-types",
  icon: AccountCircle,
  options: { label: "Roll Types" },
  list: RollTypeList,
  create: RollTypeCreate,
  edit: RollTypeEdit
};

export default rollTypes;
