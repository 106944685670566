import React from "react";
import {
  Show,
  SimpleShowLayout,
  TextField,
  UPDATE,
  NumberField,
  Datagrid,
  ReferenceManyField,
  FunctionField,
  showNotification,
  refreshView
} from "react-admin";
import { Typography, Button, Grid } from "@material-ui/core";
import { dataProvider } from "../../providers";
import { ListButton } from "../../elements/list-button";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { push } from "react-router-redux";

const linkClick = (_, __, record) => {
  return `/merchant/order-items/${record.id}/edit`;
};

const editPaymentsClick = (_, __, record) => {
  return `/merchant/payments/${record.id}/edit`;
};


export const OrderDetails = props => (
  <Show title={<Title />} actions={<Actions />} {...props}>
    <SimpleShowLayout>
      <FunctionField
        label="Total Cost"
        render={record => `UGX ${record.cost}`}
      />
      <FunctionField
        label="Status"
        render={record => record.status.toUpperCase()}
      />
      <ReferenceManyField
        reference="merchant/order-items"
        target="orderId"
        label={"Order Items"}
      >
        <Datagrid rowClick={linkClick}>
          <TextField source="id" />
          <TextField label="Stickers" source="rollType.name" />
          <NumberField source="quantity" />
          <NumberField label="Unit Price" source="unitPrice" />
          <NumberField label="Total" source="totalAmount" />
        </Datagrid>
      </ReferenceManyField>

      <ReferenceManyField
        reference="merchant/payments"
        target="orderId"
        label={"Payment"}
      >
        <Datagrid rowClick={editPaymentsClick}>
          <TextField source="id" />
          <TextField label="Payment Method" source="method" />
          <NumberField source="amount" />
          <TextField label="Bank" source="bank" />
          <TextField label="Reference" source="reference" />
        </Datagrid>
      </ReferenceManyField>


    </SimpleShowLayout>
  </Show>
);

const Title = ({ record }) => <span>Order #{record && record.id}</span>;

const Actions = ({ data = {}, resource }) => {
  return (
    <Grid
      spacing={16}
      alignItems="center"
      container
      style={{ padding: "0 0 32px" }}
    >
      <Grid item>
        <ListButton resource={resource} />
      </Grid>
      <Grid item style={{ flex: 1 }}>
        <Typography variant="display1">Order #{data.id}</Typography>
      </Grid>
      <Grid item>
        <AddItemButton order={data} />
      </Grid>
      <Grid item>
       <AddPaymentButton order={data} />
      </Grid>
      <Grid item>
        <SubmitButton order={data} />
      </Grid>
      <Grid item>
        <CancelButton order={data} />
      </Grid>
    </Grid>
  );
};

const AddItemButton = ({ order }) => {
  if (order.status !== "draft") {
    return <div />;
  }

  return (
    <Button
      variant="outlined"
      color="secondary"
      component={Link}
      to={{
        pathname: "/merchant/order-items/create",
        state: { record: { orderId: order.id } }
      }}
    >
      Add Item
    </Button>
  );
};

const AddPaymentButton = ({ order }) => {
  if (order.status !== "submitted") {
    return <div />;
  }

  return (
    <Button
      variant="outlined"
      color="secondary"
      component={Link}
      to={{
        pathname: "/merchant/payments/create",
        state: { record: { orderId: order.id } }
      }}
    >
      Payment
    </Button>
  );
};

const Submit = ({ order, refreshView, showNotification }) => {
  if (!order.cost || order.status !== "draft") {
    return <div />;
  }

  function handleClick() {
    dataProvider(UPDATE, "merchant/orders", {
      id: order.id,
      data: { status: "submitted" }
    }).then(() => {
      showNotification("Order Submitted");
      refreshView();
    });
  }

  return (
    <Button variant="raised" color="secondary" onClick={handleClick}>
      Submit
    </Button>
  );
};

const Cancel = ({ order, refreshView, showNotification }) => {
  if (order.status === "draft" || order.status === "cancelled") {
    return <div />;
  }

  function handleClick() {
    dataProvider(UPDATE, "merchant/orders", {
      id: order.id,
      data: { status: "cancelled" }
    }).then(() => {
      showNotification("Order Submitted");
      refreshView();
    });
  }

  return (
    <Button variant="outlined" color="secondary" onClick={handleClick}>
      Cancel
    </Button>
  );
};

const SubmitButton = connect(
  null,
  { showNotification, refreshView, push }
)(Submit);

const CancelButton = connect(
  null,
  { showNotification, refreshView, push }
)(Cancel);
