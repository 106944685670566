import React from "react";
import {
  Create,
  SimpleForm,
  ReferenceInput,
  SelectInput,
  TextInput
} from "react-admin";

export const CreateDocumentType = props => {
  return (
    <Create title="Create Document Type" {...props}>
      <SimpleForm redirect={redirect}>
        <ReferenceInput
          label="Merchant"
          source="merchantId"
          reference="admin/merchants"
        >
          <SelectInput optionText="company" />
        </ReferenceInput>
        <TextInput source="name" />
      </SimpleForm>
    </Create>
  );
};

const redirect = (_basePath, _id, data) =>
  `/admin/merchants/${data.merchantId}/show/document-types`;
