import React from "react";
import {
  Edit,
  SimpleForm,
  TextInput,
  TextField,
  SelectInput
} from "react-admin";

import { merchantRoles } from "../../constants";
import { EditToolbar } from "../../elements/edit-toolbar";

export const MerchantEdit = props => (
  <Edit title={"Edit User"} {...props}>
    <SimpleForm toolbar={<EditToolbar />} redirect={redirect}>
      <TextField source="id" />
      <TextInput source="name" />
      <TextInput source="email" />
      <SelectInput source="merchantRole" choices={merchantRoles} />
      <TextInput source="password" type="password" />
    </SimpleForm>
  </Edit>
);

const redirect = (_basePath, _id) => `/merchant/users`;
