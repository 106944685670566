import { ShopTwo } from "@material-ui/icons";
import { PackageCreate } from "./create";
import { EditPackage } from "./edit";

const packages = {
  name: "admin/packages",
  options: {
    label: "Packages"
  },
  icon: ShopTwo,
  create: PackageCreate,
  edit: EditPackage
};

export default packages;
