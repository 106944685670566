import { Assignment } from "@material-ui/icons";
import { MerchantRollList } from "./list";

const merchantRolls = {
  name: "merchant/merchant-rolls",
  options: {
    label: "Tags"
  },
  icon: Assignment,
  list: MerchantRollList
};

export default merchantRolls;
