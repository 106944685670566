import React from "react";
import {
  Create,
  SimpleForm,
  ReferenceInput,
  SelectInput,
  NumberInput
} from "react-admin";

export const PrintRunCreate = props => (
  <Create title="Create Print Job" {...props}>
    <SimpleForm redirect={redirect}>
      <ReferenceInput
        label="Roll Type"
        source="rollTypeId"
        reference="admin/roll-types"
      >
        <SelectInput optionText="name" />
      </ReferenceInput>
      <NumberInput label="Number of Rolls" source="volume" />
    </SimpleForm>
  </Create>
);

const redirect = (_basePath, _id) => `/admin/print-runs`;
