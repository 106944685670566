import React from "react";
import {
  Create,
  SimpleForm,
  TextInput,
  SelectInput,
  ReferenceInput,
  ReferenceManyField,
  FormDataConsumer
} from "react-admin";
import { AttributeGrid } from "../../elements/attribute-grid";

export const CreateDocument = props => {
  return (
    <Create title="Create Document" {...props}>
      <SimpleForm redirect={redirect} defaultValue={{ attributes: {} }}>
        <ReferenceInput
          label="Document Type"
          source="documentTypeId"
          reference="merchant/document-types"
          addLabel={false}
        >
          <SelectInput optionText="name" />
        </ReferenceInput>
        <TextInput source="serial" />
        <FormDataConsumer>
          {({ formData }) => {
            if (formData && formData.documentTypeId) {
              return (
                <ReferenceManyField
                  {...props}
                  label="Document Attributes"
                  reference="merchant/document-attributes"
                  target="documentTypeId"
                  record={{ id: formData.documentTypeId }}
                >
                  <AttributeGrid />
                </ReferenceManyField>
              );
            }
          }}
        </FormDataConsumer>
      </SimpleForm>
    </Create>
  );
};

const redirect = (_basePath, _id, data) => `/merchant/documents`;
