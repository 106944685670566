import React from "react";
import { Create, SimpleForm, TextInput, SelectInput } from "react-admin";
import { merchantType } from "../../constants";

export const MerchantCreate = props => (
  <Create title="Register Merchant" {...props}>
    <SimpleForm redirect={redirect}>
      <TextInput source="company" />
      <SelectInput source="merchantType" choices={merchantType} />
      <TextInput source="phoneNumber" />
      <TextInput source="email" />
      <TextInput source="address" />
    </SimpleForm>
  </Create>
);

const redirect = (_basePath, id) => `/admin/merchants/${id}/show`;
