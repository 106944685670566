import { Book } from "@material-ui/icons";
import { CreateDocumentType } from "./create";
import { ShowDocumentType } from "./show";
import { EditDocumentType } from "./edit";

const documentType = {
  name: "admin/document-types",
  options: {
    label: "Documents"
  },
  icon: Book,
  create: CreateDocumentType,
  edit: EditDocumentType,
  show: ShowDocumentType
};

export default documentType;
