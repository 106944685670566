import React from "react";
import { List, Datagrid, TextField, DateField, EmailField } from "react-admin";

export const UserList = props => (
  <List title="Users" {...props}>
    <Datagrid rowClick="edit">
      <TextField source="id" />
      <TextField source="name" />
      <EmailField source="email" />
      <DateField label="Created" source="createdAt" />
      <DateField label="Updated" source="updatedAt" />
    </Datagrid>
  </List>
);
