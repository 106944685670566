import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { LinearProgress } from "@material-ui/core";

const BorderLinearProgress = withStyles({
  root: {
    height: 10
  },
  bar: {
    borderRadius: 20,
    backgroundColor: "#ff6c5c"
  }
})(LinearProgress);

export default function PercentageField({ record }) {
  return (
    <BorderLinearProgress
      variant="determinate"
      value={record.percentage.percentage}
    />
  );
}
