import React from "react";
import {
  Edit,
  SimpleForm,
  ReferenceInput,
  SelectInput,
  ReferenceManyField,
  FormDataConsumer
} from "react-admin";
import { AttributeGrid } from "../../elements/attribute-grid";
import { EditToolbar } from "../../elements/edit-toolbar";

export const EditBatch = props => {
  return (
    <Edit title="Edit Batch" {...props}>
      <SimpleForm toolbar={<EditToolbar />} redirect={redirect}>
        <ReferenceInput
          label="Product"
          source="productId"
          reference="merchant/products"
          disabled
        >
          <SelectInput optionText="name" />
        </ReferenceInput>

        <FormDataConsumer>
          {({ formData, ...props }) => {
            if (formData.productId) {
              return (
                <ReferenceManyField
                  {...props}
                  label="Batch Attributes"
                  reference="merchant/batch-attributes"
                  target="brandId"
                  record={{ id: formData.brandId }}
                >
                  <AttributeGrid />
                </ReferenceManyField>
              );
            }
          }}
        </FormDataConsumer>
      </SimpleForm>
    </Edit>
  );
};

const redirect = () => `/merchant/batches`;
