import React from "react";
import {
  Edit,
  LongTextInput,
  SimpleForm,
  TextInput,
  TextField,
  NumberInput
} from "react-admin";
import { EditToolbar } from "../../elements/edit-toolbar";

export const Title = ({ record }) => <span>Roll Type #{record.id}</span>;

export const RollTypeEdit = props => (
  <Edit title={<Title />} {...props}>
    <SimpleForm toolbar={<EditToolbar />} redirect={redirect}>
      <TextField source="id" />
      <TextInput source="name" />
      <LongTextInput source="description" />
      <NumberInput label="Number of Tags" source="size" />
      <NumberInput label="Price" source="price" />
    </SimpleForm>
  </Edit>
);

const redirect = (_basePath, _id) => `/admin/roll-types`;
