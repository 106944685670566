import React from "react";
import {
  TabbedShowLayout,
  Tab,
  Show,
  ReferenceManyField,
  SimpleList,
  Datagrid,
  TextField,
  ReferenceField,
  DateField
} from "react-admin";
import { CardActions, Typography } from "@material-ui/core";
import { SimpleMenu } from "../../elements/menu";
import { AttributesField } from "../../elements/attribute-field";

export const ShowBrand = props => (
  <Show title={<Title />} actions={<Actions />} {...props}>
    <TabbedShowLayout>
      <Tab label="Products">
        <ReferenceManyField
          reference="admin/products"
          target="brandId"
          addLabel={false}
        >
          <Datagrid rowClick="show">
            <TextField source="id" />
            <ReferenceField
              source="brandId"
              reference="admin/brands"
              linkType={false}
            >
              <TextField source="name" />
            </ReferenceField>
            <AttributesField label="Attributes" source="properties" />
            <DateField source="createdAt" />
          </Datagrid>
        </ReferenceManyField>
      </Tab>
      <Tab label="Product Attributes" path="product-attributes">
        <ReferenceManyField
          reference="admin/product-attributes"
          target="brandId"
          addLabel={false}
        >
          <SimpleList
            linkType="edit"
            primaryText={record => record.name}
            secondaryText={record => record.type}
          />
        </ReferenceManyField>
      </Tab>
      <Tab label="Batch Attributes" path="batch-attributes">
        <ReferenceManyField
          reference="admin/batch-attributes"
          target="brandId"
          addLabel={false}
        >
          <SimpleList
            linkType="edit"
            primaryText={record => record.name}
            secondaryText={record => record.type}
          />
        </ReferenceManyField>
      </Tab>
    </TabbedShowLayout>
  </Show>
);

const Title = ({ record }) => <span>{record && record.name}</span>;

const Actions = ({ data }) => {
  const { name, id: brandId, merchantId } = data || {};
  return (
    <CardActions>
      <Typography style={{ flex: 1 }} variant="display1">
        {name}
      </Typography>
      <SimpleMenu
        items={[
          {
            label: "Create Product",
            pathname: "/admin/products/create",
            state: { record: { brandId } }
          },
          {
            label: "Create Product Attribute",
            pathname: "/admin/product-attributes/create",
            state: { record: { brandId, merchantId } }
          },
          {
            label: "Create Batch Attribute",
            pathname: "/admin/batch-attributes/create",
            state: { record: { brandId } }
          },
          {
            label: "Edit Brand",
            pathname: `/admin/brands/${brandId}`
          }
        ]}
      />
    </CardActions>
  );
};
