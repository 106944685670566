import React from "react";
import { Create, SimpleForm } from "react-admin";

export const CreateOrder = props => (
  <Create title="Create Order" {...props}>
    <SimpleForm redirect={redirect} />
  </Create>
);

const redirect = (_basePath, _id, data) =>
  `/merchant/orders/${data.merchantId}/show`;
