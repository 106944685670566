import { Book } from "@material-ui/icons";
import { CreateProductAttribute } from "./create";
import { EditProductAttribute } from "./edit";

const productAttributes = {
  name: "admin/product-attributes",

  options: {
    label: "Products Attributes"
  },
  icon: Book,
  create: CreateProductAttribute,
  edit: EditProductAttribute
};

export default productAttributes;
