import React from "react";
import {
  List,
  Datagrid,
  TextField,
  ReferenceField,
  DateField
} from "react-admin";
import { ProgressField } from "../fields/progress";

export const PrintRunList = props => (
  <List title="Print Jobs" {...props}>
    <Datagrid rowClick="show">
      <TextField source="id" />
      <ReferenceField
        label="Roll Type"
        source="rollTypeId"
        reference="admin/roll-types"
        linkType={false}
      >
        <TextField source="name" />
      </ReferenceField>
      <TextField label="Number of Rolls" source="volume" />
      <ProgressField label="Status" source="progress" />
      <DateField label="Created" source="createdAt" showTime />
      <DateField label="Updated" source="updatedAt" showTime />
    </Datagrid>
  </List>
);
