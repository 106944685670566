import React from "react";
import {
  Create,
  SimpleForm,
  ReferenceInput,
  SelectInput,
  TextInput,
  FormDataConsumer
} from "react-admin";

export const CreateActivations = props => {
  return (
    <Create title={"Activate"} {...props}>
      <SimpleForm redirect={redirect} defaultValue={{ attributes: {} }}>
        <ReferenceInput
          label="Batch"
          source="batchId"
          reference="merchant/batches"
        >
          <SelectInput optionText="name" />
        </ReferenceInput>

        <FormDataConsumer>
          {({ formData, ...props }) => {
            return (
              <ReferenceInput
                label="Packaging"
                source="packageId"
                reference="merchant/packages"
                filter={{ productId: formData && formData.productId }}
              >
                <SelectInput optionText="name" />
              </ReferenceInput>
            );
          }}
        </FormDataConsumer>
        <TextInput label="Start Serial" source="start" />
        <TextInput label="End Serial" source="end" />
      </SimpleForm>
    </Create>
  );
};

const redirect = (_basePath, _id, data) =>
  `/merchant/batches/${data.batchId}/show`;
