import React from "react";
import {
  Create,
  SimpleForm,
  LongTextInput,
  TextInput,
  NumberInput
} from "react-admin";

export const RollTypeCreate = props => (
  <Create title="Create Roll Type" {...props}>
    <SimpleForm redirect={redirect}>
      <TextInput source="name" />
      <LongTextInput source="description" />
      <NumberInput label="Number of Tags" source="size" />
      <NumberInput label="Price" source="price" />
    </SimpleForm>
  </Create>
);

const redirect = (_basePath, _id) => `/admin/roll-types`;
