import { Book } from "@material-ui/icons";
import { CreateDocumentAttribute } from "./create";
import { EditDocumentAttribute } from "./edit";

const documentAttributes = {
  name: "admin/document-attributes",

  options: {
    label: "Document Attributes"
  },
  icon: Book,
  create: CreateDocumentAttribute,
  edit: EditDocumentAttribute
};

export default documentAttributes;
