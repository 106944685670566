import React from "react";
import { List, Datagrid, TextField, ReferenceField } from "react-admin";
import { AttributesField } from "../../elements/attribute-field";

export const ListProducts = props => (
  <List title="Products" {...props}>
    <Datagrid rowClick="show">
      <TextField source="id" />
      <ReferenceField
        label="Merchant"
        source="brandId"
        reference="admin/brands"
        linkType={false}
      >
        <ReferenceField
          source="merchantId"
          reference="admin/merchants"
          linkType={false}
        >
          <TextField source="company" />
        </ReferenceField>
      </ReferenceField>
      <ReferenceField
        source="brandId"
        reference="admin/brands"
        linkType={false}
      >
        <TextField source="name" />
      </ReferenceField>
      <AttributesField label="Attributes" source="properties" />
    </Datagrid>
  </List>
);
