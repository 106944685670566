import { Book } from "@material-ui/icons";

const products = {
  name: "merchant/products",
  options: {
    label: "Products"
  },
  icon: Book
};

export default products;
