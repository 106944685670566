import { AccountBalance } from "@material-ui/icons";
import { MerchantList } from "./list";
import { MerchantCreate } from "./create";
import { MerchantEdit } from "./edit";
import { MerchantShow } from "./show";

const merchants = {
  name: "admin/merchants",
  options: {
    label: "Merchants"
  },
  icon: AccountBalance,
  list: MerchantList,
  create: MerchantCreate,
  show: MerchantShow,
  edit: MerchantEdit
};

export default merchants;
