import React from "react";
import {
  Create,
  SimpleForm,
  TextInput,
  ReferenceInput,
  SelectInput,
  FormDataConsumer
} from "react-admin";

export const CreateProductAttribute = props => (
  <Create title="Create Product Attribute" {...props}>
    <SimpleForm redirect={redirect}>
      <FormDataConsumer>
        {({ formData, ...props }) => {
          return (
            <ReferenceInput
              label="Brand"
              source="brandId"
              filter={{ merchantId: formData && formData.merchantId }}
              reference="admin/brands"
            >
              <SelectInput optionText="name" />
            </ReferenceInput>
          );
        }}
      </FormDataConsumer>

      <TextInput source="name" label="Name of Attribute" />
      <SelectInput
        source="type"
        choices={[
          { id: "text", name: "Text" },
          { id: "date", name: "Date" },
          { id: "number", name: "Number" }
        ]}
      />
      <FormDataConsumer>
        {({ formData, ...props }) => {
          if (formData.type === "number") {
            return <TextInput source="units" {...props} />;
          }
        }}
      </FormDataConsumer>
    </SimpleForm>
  </Create>
);

const redirect = (_basePath, _id, data) =>
  `/admin/brands/${data.brandId}/show/product-attributes`;
