import React from "react";
import {
  List,
  Datagrid,
  TextField,
  DateField,
  ReferenceField
} from "react-admin";
import { AttributesField } from "../../elements/attribute-field";

export const MerchantList = props => (
  <List title="Batches" {...props}>
    <Datagrid rowClick="show">
      <TextField source="id" />
      <ReferenceField
        label="Product"
        source="productId"
        reference="merchant/products"
        linkType={false}
      >
        <ReferenceField
          source="brandId"
          reference="merchant/brands"
          linkType={false}
        >
          <TextField source="name" />
        </ReferenceField>
      </ReferenceField>
      <AttributesField label="Attributes" source="properties" />
      <DateField source="createdAt" showTime />
    </Datagrid>
  </List>
);
