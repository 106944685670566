import React from "react";
import { Admin, Resource } from "react-admin";

import { authProvider } from "./providers/auth";
import { client } from "./providers/client";

import LoginWithTheme from "./elements/login";
import admin from "./admin";
import {
  brandMerchantAdmin,
  documentMerchantAdmin,
  brandMerchantClerk,
  documentMerchantClerk
} from "./merchant";
import { dataProvider } from "./providers";
import { realTimeProvider } from "./providers/realtime";

const App = () => (
  <Admin
    title="VerifyIT"
    authProvider={authProvider(client)}
    dataProvider={dataProvider}
    customSagas={[realTimeProvider]}
    loginPage={LoginWithTheme}
  >
    {user => {
      const resources = getResources(user);
      return Object.keys(resources).map(resourceName => {
        return <Resource {...resources[resourceName]} />;
      });
    }}
  </Admin>
);

function getResources(user) {
  if (user.role === "admin") {
    return admin;
  }

  if (user.merchantRole === "admin") {
    return user.merchant.merchantType === "brand-creator"
      ? brandMerchantAdmin
      : documentMerchantAdmin;
  }

  if (user.merchantRole === "clerk") {
    return user.merchant.merchantType === "brand-creator"
      ? brandMerchantClerk
      : documentMerchantClerk;
  }

  return [];
}

export default App;
