import AccountCircle from "@material-ui/icons/SupervisorAccount";
import { CreatePayments } from "./create";
import { EditPayments } from "./edit";

const payments = {
  name: "merchant/payments",
  options: {
    label: "Payments"
  },
  icon: AccountCircle,
  create: CreatePayments,
  edit: EditPayments
};

export default payments;
