import React from "react";
import { DateInput, NumberInput, TextInput, SelectInput } from "react-admin";

export const AttributeGrid = props => {
  const attributes = props.data || {};
  return (
    <div>
      {Object.keys(attributes)
        .map(attributeId => {
          const attribute = attributes[attributeId];
          const isDisabled = props.checkEditable && !attribute.isEditable;
          if (attribute.type === "date") {
            return (
              <DateInput
                key={attribute.id}
                source={`attributes.${attribute.id}`}
                label={attribute.name}
                disabled={isDisabled}
              />
            );
          }
          if (attribute.type === "number") {
            return (
              <NumberInput
                key={attribute.id}
                source={`attributes.${attribute.id}`}
                label={attribute.name}
                disabled={isDisabled}
              />
            );
          }
          if (attribute.type === "drop-down") {
            const options = attribute.options.map(element => {
              return {
                id: element.value,
                name: element.value
              };
            });
            return (
              <SelectInput
                key={attribute.id}
                source={`attributes.${attribute.id}`}
                label={attribute.name}
                disabled={isDisabled}
                choices={options}
              />
            );
          }

          return (
            <TextInput
              key={attribute.id}
              source={`attributes.${attribute.id}`}
              label={attribute.name}
              disabled={isDisabled}
            />
          );
        })
        .map((input, i) => (
          <div key={i}>{input}</div>
        ))}
    </div>
  );
};
