import React from "react";
import {
  Edit,
  SimpleForm,
  ReferenceInput,
  TextInput,
  SelectInput,
  ReferenceManyField,
  FormDataConsumer
} from "react-admin";

import { EditToolbar } from "../../elements/edit-toolbar";
import { AttributeGrid } from "../../elements/attribute-grid";
export const EditDocument = props => (
  <Edit title={<Title />} {...props}>
    <SimpleForm
      toolbar={<EditToolbar />}
      redirect={redirect}
      defaultValue={{ attributes: {} }}
    >
      <ReferenceInput
        label="Document Type"
        source="documentTypeId"
        reference="merchant/document-types"
        disabled
      >
        <SelectInput optionText="name" />
      </ReferenceInput>
      <TextInput label="Tag Serial" source="activation.serial" disabled />
      <FormDataConsumer>
        {({ formData, ...props }) => {
          if (formData.documentTypeId) {
            return (
              <ReferenceManyField
                {...props}
                label="Document Attributes"
                reference="merchant/document-attributes"
                target="documentTypeId"
                record={{ id: formData.documentTypeId }}
              >
                <AttributeGrid checkEditable={true} />
              </ReferenceManyField>
            );
          }
        }}
      </FormDataConsumer>
    </SimpleForm>
  </Edit>
);

const Title = ({ record }) => <span>{record.company}</span>;

const redirect = (_basePath, _id, data) =>
  `/merchant/documents/${data.id}/show`;
