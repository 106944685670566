export const userRoles = [
  { id: "admin", name: "Administrator" },
  { id: "merchant", name: "Merchant" }
];

export const merchantType = [
  { id: "brand-creator", name: "Brand Creator" },
  { id: "document-issuer", name: "Document Issuer" }
];

export const merchantRoles = [
  { id: "admin", name: "Administrator" },
  { id: "clerk", name: "Clerk" }
];
