import React from "react";
import {
  Create,
  SimpleForm,
  TextInput,
  ArrayInput,
  SimpleFormIterator,
  ReferenceInput,
  SelectInput,
  BooleanInput,
  FormDataConsumer
} from "react-admin";

export const CreateDocumentAttribute = props => (
  <Create title="Create Document Attribute" {...props}>
    <SimpleForm redirect={redirect}>
      <ReferenceInput
        label="Document Type"
        source="documentTypeId"
        reference="admin/document-types"
      >
        <SelectInput optionText="name" />
      </ReferenceInput>
      <TextInput source="name" label="Name Of Attribute" />
      <SelectInput
        source="type"
        choices={[
          { id: "text", name: "Text" },
          { id: "date", name: "Date" },
          { id: "number", name: "Number" },
          { id: "drop-down", name: "Drop Down" }
        ]}
      />
      <BooleanInput
        label="Enable editing when updating a document?"
        source="isEditable"
        defaultValue={true}
      />
      <FormDataConsumer>
        {({ formData, ...props }) => {
          if (formData.type === "number") {
            return <TextInput source="units" {...props} />;
          }

          if (formData.type === "drop-down") {
            return (
              <ArrayInput source="options" {...props}>
                <SimpleFormIterator>
                  <TextInput label="Option" source="value" type="text" />
                </SimpleFormIterator>
              </ArrayInput>
            );
          }

          if (formData.type === "date") {
            return (
              <>
                <BooleanInput
                  label="Is Issuing Date?"
                  source="isIssuingDate"
                  {...props}
                />
                <BooleanInput
                  label="Is Expiration Date?"
                  source="isExpirationDate"
                  {...props}
                />
              </>
            );
          }
        }}
      </FormDataConsumer>
    </SimpleForm>
  </Create>
);

const redirect = (_basePath, _id, data) =>
  `/admin/document-types/${data.documentTypeId}/show/document-attributes`;
