import React from "react";
import {
  Edit,
  SimpleForm,
  SelectInput,
  ReferenceInput,
  TextInput
} from "react-admin";
import { EditToolbar } from "../../elements/edit-toolbar";

export const Title = ({ record }) => <span>Roll #{record.rollId}</span>;

export const EditMerchantRoll = props => (
  <Edit title={<Title />} {...props}>
    <SimpleForm toolbar={<EditToolbar />} redirect={redirect}>
      <ReferenceInput
        label="Merchant"
        source="merchantId"
        reference="admin/merchants"
      >
        <SelectInput optionText="company" />
      </ReferenceInput>
      <TextInput source="rollId" />
    </SimpleForm>
  </Edit>
);

const redirect = (_basePath, id, data) =>
  `/admin/merchants/${data.merchantId}/show/rolls`;
