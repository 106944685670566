import AccountCircle from "@material-ui/icons/SupervisorAccount";
import { CreateOrderDetails } from "./create";
import { EditOrderDetails } from "./edit";

const orderItems = {
  name: "merchant/order-items",
  options: {
    label: "Order Details"
  },
  icon: AccountCircle,
  create: CreateOrderDetails,
  edit: EditOrderDetails
};

export default orderItems;
