import React from "react";
import {
  List,
  Datagrid,
  TextField,
  DateField,
  ExportButton,
  RefreshButton,
  ReferenceField
} from "react-admin";
import { CardActions } from "@material-ui/core";

export const OrderList = props => (
  <List title="Orders" actions={<Actions />} {...props}>
    <Datagrid rowClick="show">
      <TextField source="id" />
      <ReferenceField
        label="Merchant"
        source="merchantId"
        reference="admin/merchants"
        linkType={null}
      >
        <TextField source="company" />
      </ReferenceField>
      <TextField source="status" />
      <DateField label="Created" source="createdAt" />
    </Datagrid>
  </List>
);

const Actions = ({
  bulkActions,
  basePath,
  currentSort,
  displayedFilters,
  exporter,
  filters,
  filterValues,
  onUnselectItems,
  resource,
  selectedIds,
  showFilter,
  total
}) => {
  return (
    <CardActions>
      {bulkActions &&
        React.cloneElement(bulkActions, {
          basePath,
          filterValues,
          resource,
          selectedIds,
          onUnselectItems
        })}
      {filters &&
        React.cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: "button"
        })}
      <ExportButton
        disabled={total === 0}
        resource={resource}
        sort={currentSort}
        filter={filterValues}
        exporter={exporter}
      />
      <RefreshButton />
    </CardActions>
  );
};
