import React from "react";
import {
  Create,
  SimpleForm,
  SelectInput,
  ReferenceInput,
  NumberInput
} from "react-admin";

export const CreateOrderDetails = ({ permissions, ...props }) => {
  return (
    <Create title="Create Order Items" {...props}>
      <SimpleForm redirect={redirect}>
        <ReferenceInput
          label="Roll Type"
          source="rollTypeId"
          reference="merchant/roll-types"
          addLabel={false}
        >
          <SelectInput optionText="name" />
        </ReferenceInput>
        <NumberInput source="quantity" />
      </SimpleForm>
    </Create>
  );
};

const redirect = (_basePath, _id, data) =>
  `/merchant/orders/${data.orderId}/show`;
