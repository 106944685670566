import React from "react";
import { List, Datagrid, TextField, DateField, CardActions } from "react-admin";
import { Button } from "@material-ui/core";
import PercentageField from "../fields/progress";

export const MerchantRollList = props => (
  <List title={"Rolls"} {...props} actions={<PostActions />}>
    <Datagrid>
      <TextField source="id" />
      <TextField source="rollType" />
      <PercentageField label="Usage" source="percentage.percentage" />
      <TextField label="Remaining Roll" source="percentage.size" />
      <DateField source="createdAt" label="Assigned At" showTime />
    </Datagrid>
  </List>
);

const PostActions = ({
  bulkActions,
  basePath,
  displayedFilters,
  filters,
  filterValues,
  onUnselectItems,
  resource,
  selectedIds,
  showFilter,
  setFilters
}) => (
  <CardActions>
    {bulkActions &&
      React.cloneElement(bulkActions, {
        basePath,
        filterValues,
        resource,
        selectedIds,
        onUnselectItems
      })}
    {filters &&
      React.cloneElement(filters, {
        resource,
        showFilter,
        displayedFilters,
        filterValues,
        context: "button"
      })}
    <Button
      color="primary"
      onClick={() => {
        if (!filterValues.showAllRolls) {
          return setFilters({ showAllRolls: true });
        }
        return setFilters({});
      }}
    >
      {" "}
      {!filterValues.showAllRolls ? "Show All Rolls" : "Show Remaining Rolls"}
    </Button>
  </CardActions>
);
